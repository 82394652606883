<template>
  <div class="smx-opening-animation-wrap">
    <svg id="smx-opening-animation" class="smx-opening-animation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 900">

      <g id="smx-oa-icons" data-name="icons">
        <path id="smx-oa-icons--icon__blue" data-name="blue icon" class="smx-oa-blue" d="M870.84,554.33H840.09A6.25,6.25,0,0,0,835.67,565l8.44,8.43L825,592.54l-19.11-19.1a8.32,8.32,0,0,0-11.78,0l-17.89,17.88a4.17,4.17,0,0,0,0,5.9l5.89,5.89a4.16,4.16,0,0,0,5.89,0l12-12,19.11,19.11a8.34,8.34,0,0,0,11.78,0l25-25,8.44,8.44A6.25,6.25,0,0,0,875,589.24V558.49A4.16,4.16,0,0,0,870.84,554.33Z"/>
        <circle id="smx-oa-icons--circle__blue" data-name="blue circle" class="smx-oa-icons--circle smx-oa-blue" cx="825" cy="583.49" r="50"/>
        <path id="smx-oa-icons--icon__yellow" data-name="yellow icon" class="smx-oa-yellow" d="M763,534.11l-90.54,52.23a4.69,4.69,0,0,0,.43,8.44l20.76,8.71L749.75,554a1.17,1.17,0,0,1,1.68,1.62l-47,57.33v15.73a4.68,4.68,0,0,0,8.3,3.08l12.4-15.09,24.34,10.19a4.7,4.7,0,0,0,6.45-3.55L769.93,539A4.69,4.69,0,0,0,763,534.11Z"/>
        <circle id="smx-oa-icons--circle__yellow" data-name="yellow circle" class="smx-oa-icons--circle smx-oa-yellow" cx="720" cy="583.49" r="50"/>
        <path id="smx-oa-icons--icon__red" data-name="red icon" class="smx-oa-red" d="M656.67,539.05H573.33a8.33,8.33,0,0,0-8.33,8.33v55.56a8.33,8.33,0,0,0,8.33,8.33h33.34l-2.78,8.33h-12.5a4.17,4.17,0,1,0,0,8.34h47.22a4.17,4.17,0,0,0,0-8.34h-12.5l-2.78-8.33h33.34a8.33,8.33,0,0,0,8.33-8.33V547.38A8.33,8.33,0,0,0,656.67,539.05Z"/>
        <circle id="smx-oa-icons--circle__red" data-name="red circle" class="smx-oa-icons--circle smx-oa-red" cx="615" cy="583.49" r="50"/>
        <path id="smx-oa-icons--path__red" class="smx-oa-stroke" d="M1438.11,481.65c-64.3,201.7-246.86,336.2-442.24,332.75C753.51,810.14,622.33,595.92,615,583.49"/>
        <path id="smx-oa-icons--path__yellow" class="smx-oa-stroke" d="M1438.11,494.44c-56.1,176-215.37,293.31-385.82,290.31C840.84,781,726.39,594.13,720,583.29"/>
        <path id="smx-oa-icons--path__blue" class="smx-oa-stroke" d="M1438.11,507.64c-47.9,150.24-183.88,250.42-329.41,247.86-180.53-3.18-278.24-162.74-283.7-172"/>
      </g>
      <g id="smx-oa-scene-1" data-name="scene 1">
        <g class="smx-oa-scene-1--welcome">
          <path class="smx-oa-scene-1--textpath" d="M239.83,281.19,217,357.1h-17.3L183.83,305l-16.2,52.11h-16l-22.31-75.91h18.2l14.11,48.5,15.1-48.5H193l14.7,48.7,14.6-48.7Z"/>
          <path class="smx-oa-scene-1--textpath" d="M294.83,333.79H253.52c.8,6.7,5.31,11.3,12.71,11.3,5.5,0,9.2-1.8,10.9-5h16.6c-3.2,10.51-13.4,18-27.7,18-17.51,0-29-11.5-29-28.91s11.3-29,28.91-29,28.9,11.4,28.9,28.8Zm-41.11-10.7h24.61c-1.1-6.3-6-9.9-12.4-9.9A12.27,12.27,0,0,0,253.72,323.09Z"/>
          <path class="smx-oa-scene-1--textpath" d="M319.23,277.59V357.1H303.62V277.59Z"/>
          <path class="smx-oa-scene-1--textpath" d="M383.63,323.29h-16.4a10.76,10.76,0,0,0-11.21-9.9c-7.4,0-12.5,5.7-12.5,15.8,0,9.9,5.1,15.71,12.5,15.71,6.31,0,10.61-4.21,11.21-9.81h16.4c-.4,13-11.5,23.41-27.61,23.41-18,0-28.8-11.71-28.8-29.31s10.8-29.3,28.8-29.3C372.13,299.89,383.23,310.19,383.63,323.29Z"/>
          <path class="smx-oa-scene-1--textpath" d="M448.33,329.19c0,16.71-10.7,29.31-29.41,29.31s-29.5-12.6-29.5-29.31,10.7-29.3,29.5-29.3S448.33,312.49,448.33,329.19Zm-42.61,0c0,9.2,5.1,15.1,13.2,15.1s13.11-5.9,13.11-15.1-5-15.1-13.11-15.1S405.72,320,405.72,329.19Z"/>
          <path class="smx-oa-scene-1--textpath" d="M542.13,325.49V357.1h-15.6V328.19c0-9.2-3.7-14-9.6-14-5.7,0-9.6,5.2-9.6,13.9v29h-15.6V328.19c0-9.2-3.71-14-9.61-14-5.7,0-9.6,5.2-9.6,13.9v29h-15.6V301.19h15.6v6.1c2.9-4.4,7.9-7.4,14.5-7.4,7.21,0,12.91,3,16.51,8.8a19.65,19.65,0,0,1,16.9-8.8C533.53,299.89,542.13,309.69,542.13,325.49Z"/>
          <path class="smx-oa-scene-1--textpath" d="M607.63,333.79H566.32c.8,6.7,5.3,11.3,12.7,11.3,5.51,0,9.21-1.8,10.91-5h16.6c-3.2,10.51-13.4,18-27.71,18-17.5,0-29-11.5-29-28.91s11.3-29,28.91-29,28.9,11.4,28.9,28.8Zm-41.11-10.7h24.61c-1.1-6.3-6-9.9-12.4-9.9A12.27,12.27,0,0,0,566.52,323.09Z"/>
        </g>
        <g class="smx-oa-scene-1--to">
          <path class="smx-oa-scene-1--textpath" d="M686.82,314.39h-12.1V357.1h-15.6V314.39h-10.5v-13.2h10.5v-17.6h15.6v17.6h12.1Z"/>
          <path class="smx-oa-scene-1--textpath" d="M748.62,329.19c0,16.71-10.7,29.31-29.4,29.31s-29.5-12.6-29.5-29.31,10.7-29.3,29.5-29.3S748.62,312.49,748.62,329.19Zm-42.6,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S706,320,706,329.19Z"/>
        </g>
        <g class="smx-oa-scene-1--swingmix">
          <path class="smx-oa-scene-1--textpath" d="M849.93,303.79H832.42c0-5.6-4.2-9.5-11.6-9.5-6.5,0-10.6,3.4-10.6,7.7,0,4,2.4,6.5,9.3,8.1l8.6,2c16.5,3.7,23.31,10.8,23.31,23.3,0,13.21-10,22.71-29.61,22.71-19.8,0-30.6-10.1-30.6-24.91h17.5c0,6.6,5,11.5,13.2,11.5,7.9,0,12-3.9,12-9,0-4.3-2.4-6.8-9.8-8.4l-8.3-1.7c-17.3-3.5-23.2-11.7-23.2-23.3,0-12.9,10-22.1,28.6-22.1S849.93,289.79,849.93,303.79Z"/>
          <path class="smx-oa-scene-1--textpath" d="M939.63,301.19l-16.4,55.91H907.52l-11.3-35.51-11.4,35.51h-15.3l-16.4-55.91h15.2l10.5,35.6,11-35.6h13.4l10.9,35.4,10.51-35.4Z"/>
          <path class="smx-oa-scene-1--textpath" d="M961.22,285.39a9.08,9.08,0,0,1-9.4,9.4c-5.8,0-9.3-4.3-9.3-9.4s3.5-9.4,9.3-9.4A9,9,0,0,1,961.22,285.39Zm-1.5,14.5V357.1h-15.6V299.89a12.7,12.7,0,0,0,7.8,2.3A12.49,12.49,0,0,0,959.72,299.89Z"/>
          <path class="smx-oa-scene-1--textpath" d="M1024.43,325.49V357.1h-15.6V328.49c0-8.6-4.1-13.4-10.41-13.4s-10.8,5.2-10.8,13.3V357.1H972V301.19h15.6v6.3c3.1-4.5,8.8-7.6,15.91-7.6C1016.23,299.89,1024.43,309,1024.43,325.49Z"/>
          <path class="smx-oa-scene-1--textpath" d="M1090.13,301.19V360.5c0,12.2-7.5,19.8-19.71,19.8h-26.6V367.1h24.1c4.5,0,6.61-2.2,6.61-6.6V349c-3.7,4.8-9.51,7.8-17.41,7.8-14.5,0-24.8-10.1-24.8-28.61s10.3-28.3,24.8-28.3c7.9,0,13.71,2.9,17.41,7.8v-6.5Zm-15.6,26.6c0-9.1-5.2-14.5-12.91-14.5-7.5,0-13,5.4-13,14.5,0,9.3,5.5,14.9,13,14.9C1069.33,342.69,1074.53,337,1074.53,327.79Z"/>
          <path class="smx-oa-scene-1--textpath" d="M1139,281.19h17.7l21.7,32.9,21.71-32.9h17.8V357.1H1201V307.39l-22.81,33.8h-.2l-22.8-33.9V357.1H1139Z"/>
          <path class="smx-oa-scene-1--textpath" d="M1249.42,285.39a9.08,9.08,0,0,1-9.4,9.4c-5.8,0-9.3-4.3-9.3-9.4s3.5-9.4,9.3-9.4A9,9,0,0,1,1249.42,285.39Zm-1.5,14.5V357.1h-15.6V299.89a12.7,12.7,0,0,0,7.8,2.3A12.49,12.49,0,0,0,1247.92,299.89Z"/>
          <path class="smx-oa-scene-1--textpath" d="M1295,329.29l20.2,27.81h-18.4L1285,340.79l-11.51,16.31h-17.8l20.3-28.61-19.8-27.3h18.4l11.5,15.8,11.51-15.8h17.8Z"/>
          <path class="smx-oa-scene-1--textpath" d="M1339.52,353c0,9.4-4.6,15.7-13.8,18.4l-2.3.7-2.5-7.6,2.1-.6c4.3-1.3,6.7-3.1,7.8-6a11,11,0,0,1-1.8.2c-5.5,0-8.7-4-8.7-8.8,0-5.11,3.5-8.91,9.1-8.91C1335.72,340.39,1339.52,345.19,1339.52,353Z"/>
        </g>
        <g class="smx-oa-scene-1--a">
          <path class="smx-oa-scene-1--textpath" d="M185.63,445.19V477.1H170V471c-3.9,4.5-10,7.1-18.2,7.1-11.71,0-19-5.9-19-15.61,0-8.8,5.8-15.5,20.41-18l16.8-2.8c0-5.4-3.4-8.5-9.7-8.5-6.1,0-9,2.6-9.4,6.9H135.12c.3-10.3,8.7-19.9,25.21-19.9C176.43,420.19,185.63,429.19,185.63,445.19Zm-15.6,8.4v-.8l-12.8,2.1c-6.7,1.1-8.4,3.4-8.4,6.31,0,2.5,2.4,4.3,6.9,4.3C164.33,465.5,170,460.7,170,453.59Z"/>
        </g>
        <g class="smx-oa-scene-1--digital">
          <path class="smx-oa-scene-1--textpath" d="M286.83,397.59V477.1h-15.6v-6.6c-3.7,5-9.5,8-17.31,8-14.5,0-24.7-10.4-24.7-29.31s10.2-29.3,24.7-29.3c7.81,0,13.61,3,17.31,7.9v-30.2Zm-15.6,51.2c0-9.9-5.2-15.5-12.8-15.5s-12.91,5.7-12.91,15.5,5.4,15.5,12.91,15.5S271.23,458.69,271.23,448.79Z"/>
          <path class="smx-oa-scene-1--textpath" d="M316.23,405.39a9.09,9.09,0,0,1-9.41,9.4c-5.8,0-9.3-4.3-9.3-9.4s3.5-9.4,9.3-9.4A9,9,0,0,1,316.23,405.39Zm-1.5,14.5V477.1H299.12V419.89a12.7,12.7,0,0,0,7.8,2.3A12.54,12.54,0,0,0,314.73,419.89Z"/>
          <path class="smx-oa-scene-1--textpath" d="M381.53,421.19V480.5c0,12.2-7.5,19.8-19.7,19.8h-26.6V487.1h24.1c4.5,0,6.6-2.2,6.6-6.6V469c-3.7,4.79-9.5,7.8-17.4,7.8-14.5,0-24.81-10.1-24.81-28.61s10.31-28.3,24.81-28.3c7.9,0,13.7,2.9,17.4,7.8v-6.5Zm-15.6,26.6c0-9.1-5.2-14.5-12.9-14.5-7.5,0-13,5.4-13,14.5,0,9.3,5.5,14.91,13,14.91C360.73,462.7,365.93,457,365.93,447.79Z"/>
          <path class="smx-oa-scene-1--textpath" d="M408.73,405.39a9.08,9.08,0,0,1-9.4,9.4c-5.81,0-9.31-4.3-9.31-9.4s3.5-9.4,9.31-9.4A9,9,0,0,1,408.73,405.39Zm-1.5,14.5V477.1H391.62V419.89a12.7,12.7,0,0,0,7.8,2.3A12.5,12.5,0,0,0,407.23,419.89Z"/>
          <path class="smx-oa-scene-1--textpath" d="M454.93,434.39h-12.1V477.1h-15.6V434.39H416.72v-13.2h10.51v-17.6h15.6v17.6h12.1Z"/>
          <path class="smx-oa-scene-1--textpath" d="M511.63,445.19V477.1H496V471c-3.9,4.5-10,7.1-18.2,7.1-11.7,0-19-5.9-19-15.61,0-8.8,5.8-15.5,20.4-18l16.8-2.8c0-5.4-3.4-8.5-9.7-8.5-6.1,0-9,2.6-9.4,6.9H461.12c.31-10.3,8.71-19.9,25.21-19.9C502.43,420.19,511.63,429.19,511.63,445.19Zm-15.6,8.4v-.8l-12.8,2.1c-6.7,1.1-8.4,3.4-8.4,6.31,0,2.5,2.4,4.3,6.9,4.3C490.33,465.5,496,460.7,496,453.59Z"/>
          <path class="smx-oa-scene-1--textpath" d="M539.23,397.59V477.1h-15.6V397.59Z"/>
        </g>
        <g class="smx-oa-scene-1--agency">
          <path class="smx-oa-scene-1--textpath" d="M638.23,445.19V477.1h-15.6V471c-3.9,4.5-10,7.1-18.2,7.1-11.71,0-19-5.9-19-15.61,0-8.8,5.8-15.5,20.41-18l16.8-2.8c0-5.4-3.4-8.5-9.7-8.5-6.1,0-9,2.6-9.4,6.9H587.72c.3-10.3,8.7-19.9,25.21-19.9C629,420.19,638.23,429.19,638.23,445.19Zm-15.6,8.4v-.8l-12.8,2.1c-6.7,1.1-8.4,3.4-8.4,6.31,0,2.5,2.4,4.3,6.9,4.3C616.93,465.5,622.63,460.7,622.63,453.59Z"/>
          <path class="smx-oa-scene-1--textpath" d="M703.43,421.19V480.5c0,12.2-7.5,19.8-19.7,19.8h-26.6V487.1h24.1c4.5,0,6.6-2.2,6.6-6.6V469c-3.7,4.79-9.5,7.8-17.4,7.8-14.5,0-24.8-10.1-24.8-28.61s10.3-28.3,24.8-28.3c7.9,0,13.7,2.9,17.4,7.8v-6.5Zm-15.6,26.6c0-9.1-5.2-14.5-12.9-14.5-7.5,0-13,5.4-13,14.5,0,9.3,5.5,14.91,13,14.91C682.63,462.7,687.83,457,687.83,447.79Z"/>
          <path class="smx-oa-scene-1--textpath" d="M768.63,453.79h-41.3c.8,6.7,5.3,11.3,12.7,11.3,5.5,0,9.2-1.8,10.9-5h16.6c-3.2,10.51-13.4,18-27.7,18-17.5,0-29-11.5-29-28.91s11.3-29,28.9-29,28.9,11.4,28.9,28.8Zm-41.1-10.7h24.6c-1.1-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,727.53,443.09Z"/>
          <path class="smx-oa-scene-1--textpath" d="M829.43,445.49V477.1h-15.6V448.49c0-8.6-4.1-13.4-10.4-13.4s-10.8,5.2-10.8,13.3V477.1H777V421.19h15.6v6.3c3.1-4.5,8.8-7.6,15.9-7.6C821.23,419.89,829.43,429,829.43,445.49Z"/>
          <path class="smx-oa-scene-1--textpath" d="M893.13,443.29h-16.4a10.76,10.76,0,0,0-11.2-9.9c-7.4,0-12.5,5.7-12.5,15.8,0,9.9,5.1,15.7,12.5,15.7,6.3,0,10.6-4.19,11.2-9.8h16.4c-.4,13-11.5,23.41-27.6,23.41-18,0-28.81-11.71-28.81-29.31s10.81-29.3,28.81-29.3C881.63,419.89,892.73,430.19,893.13,443.29Z"/>
          <path class="smx-oa-scene-1--textpath" d="M954.63,421.19l-31,74.31h-15.1l8.6-20.5-21.81-53.81H911L925,456l14.5-34.8Z"/>
          <path class="smx-oa-scene-1--textpath" d="M969,468.2a10,10,0,1,1-10-10.11C965,458.09,969,462.49,969,468.2Z"/>
        </g>
        <circle class="smx-oa-scene-1--circle smx-oa-scene-1--circle__blue smx-oa-blue" cx="1214.02" cy="452.79" r="35"/>
        <circle class="smx-oa-scene-1--circle smx-oa-scene-1--circle__yellow smx-oa-yellow" cx="1139.02" cy="452.79" r="35"/>
        <circle class="smx-oa-scene-1--circle smx-oa-scene-1--circle__red smx-oa-red" cx="1064.02" cy="452.79" r="35"/>
        <path id="smx-oa-scene-1--motionpath__blue" class="smx-oa-scene-1--motionpath__blue smx-oa-stroke" d="M312.12,0c81.39,434.27,372.62,690.88,607.16,663,31.22-3.71,166.86-25,294.74-210.2"/>
        <path id="smx-oa-scene-1--motionpath__yellow" class="smx-oa-scene-1--motionpath__yellow smx-oa-stroke" d="M237.12,0c81.39,434.27,372.62,690.88,607.16,663,31.22-3.71,166.86-25,294.74-210.2"/>
        <path id="smx-oa-scene-1--motionpath__red" class="smx-oa-scene-1--motionpath__red smx-oa-stroke" d="M162.12,0c81.39,434.27,372.62,690.88,607.16,663,31.22-3.71,166.86-25,294.74-210.2"/>
      </g>
      <g id="smx-oa-scene-2" data-name="scene 2">
        <g class="smx-oa-scene-2--we">
          <path class="smx-oa-scene-2--textpath" d="M277,281.19l-22.8,75.91h-17.3L221,305l-16.2,52.11h-16l-22.3-75.91h18.2l14.1,48.5,15.1-48.5h16.3l14.7,48.7,14.6-48.7Z"/>
          <path class="smx-oa-scene-2--textpath" d="M332,333.79h-41.3c.8,6.7,5.3,11.3,12.7,11.3,5.5,0,9.2-1.8,10.9-5h16.6c-3.2,10.5-13.4,18-27.7,18-17.5,0-29-11.51-29-28.91s11.3-29,28.9-29S332,311.59,332,329Zm-41.1-10.7h24.6c-1.1-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,290.88,323.09Z"/>
        </g>
        <g class="smx-oa-scene-2--connect">
          <path class="smx-oa-scene-2--textpath" d="M429.08,323.29h-16.4a10.76,10.76,0,0,0-11.2-9.9c-7.4,0-12.5,5.7-12.5,15.8,0,9.9,5.1,15.7,12.5,15.7,6.3,0,10.6-4.2,11.2-9.8h16.4c-.4,13-11.5,23.41-27.6,23.41-18,0-28.81-11.71-28.81-29.31s10.81-29.3,28.81-29.3C417.58,299.89,428.68,310.19,429.08,323.29Z"/>
          <path class="smx-oa-scene-2--textpath" d="M493.78,329.19c0,16.7-10.7,29.31-29.4,29.31s-29.51-12.61-29.51-29.31,10.71-29.3,29.51-29.3S493.78,312.49,493.78,329.19Zm-42.61,0c0,9.2,5.11,15.1,13.21,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S451.17,320,451.17,329.19Z"/>
          <path class="smx-oa-scene-2--textpath" d="M554.78,325.49V357.1h-15.6V328.49c0-8.6-4.1-13.4-10.4-13.4s-10.8,5.2-10.8,13.3V357.1h-15.6V301.19H518v6.3c3.1-4.5,8.8-7.6,15.9-7.6C546.58,299.89,554.78,309,554.78,325.49Z"/>
          <path class="smx-oa-scene-2--textpath" d="M619.08,325.49V357.1h-15.6V328.49c0-8.6-4.1-13.4-10.4-13.4s-10.8,5.2-10.8,13.3V357.1H566.67V301.19h15.61v6.3c3.1-4.5,8.8-7.6,15.9-7.6C610.88,299.89,619.08,309,619.08,325.49Z"/>
          <path class="smx-oa-scene-2--textpath" d="M684.18,333.79h-41.3c.8,6.7,5.3,11.3,12.7,11.3,5.5,0,9.2-1.8,10.9-5h16.6c-3.2,10.5-13.4,18-27.7,18-17.5,0-29-11.51-29-28.91s11.3-29,28.91-29,28.9,11.4,28.9,28.8Zm-41.1-10.7h24.6c-1.1-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,643.08,323.09Z"/>
          <path class="smx-oa-scene-2--textpath" d="M746.38,323.29H730a10.76,10.76,0,0,0-11.2-9.9c-7.4,0-12.5,5.7-12.5,15.8,0,9.9,5.1,15.7,12.5,15.7,6.3,0,10.6-4.2,11.2-9.8h16.4c-.4,13-11.5,23.41-27.6,23.41-18,0-28.81-11.71-28.81-29.31s10.8-29.3,28.81-29.3C734.88,299.89,746,310.19,746.38,323.29Z"/>
          <path class="smx-oa-scene-2--textpath" d="M789,314.39h-12.1V357.1H761.27V314.39h-10.5v-13.2h10.5v-17.6h15.61v17.6H789Z"/>
        </g>
        <g class="smx-oa-scene-2--thedots">
          <path class="smx-oa-scene-2--textpath" d="M868,314.39h-12.1V357.1H840.27V314.39h-10.5v-13.2h10.5v-17.6h15.61v17.6H868Z"/>
          <path class="smx-oa-scene-2--textpath" d="M930.38,325.49V357.1h-15.6V328.49c0-8.6-4.1-13.4-10.4-13.4s-10.71,5.2-10.71,13.3l-.1-.7V357.1H878V277.59h15.6v29.9c3.1-4.5,8.71-7.6,15.91-7.6C922.18,299.89,930.38,309,930.38,325.49Z"/>
          <path class="smx-oa-scene-2--textpath" d="M996,333.79H954.67c.8,6.7,5.3,11.3,12.71,11.3,5.5,0,9.2-1.8,10.9-5h16.6c-3.2,10.5-13.4,18-27.71,18-17.5,0-29-11.51-29-28.91s11.3-29,28.91-29S996,311.59,996,329Zm-41.11-10.7h24.61c-1.1-6.3-6-9.9-12.4-9.9A12.27,12.27,0,0,0,954.87,323.09Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1094.28,277.59V357.1h-15.61v-6.6c-3.7,5-9.5,8-17.3,8-14.5,0-24.7-10.41-24.7-29.31s10.2-29.3,24.7-29.3c7.8,0,13.6,3,17.3,7.9v-30.2Zm-15.61,51.2c0-9.9-5.2-15.5-12.8-15.5s-12.9,5.7-12.9,15.5,5.4,15.5,12.9,15.5S1078.67,338.69,1078.67,328.79Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1161.08,329.19c0,16.7-10.7,29.31-29.41,29.31s-29.5-12.61-29.5-29.31,10.7-29.3,29.5-29.3S1161.08,312.49,1161.08,329.19Zm-42.61,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S1118.47,320,1118.47,329.19Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1202.48,314.39h-12.1V357.1h-15.61V314.39h-10.5v-13.2h10.5v-17.6h15.61v17.6h12.1Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1257.48,318.79h-15.6c-.1-3.6-3.3-6.4-8.61-6.4-5.1,0-7.8,1.9-7.8,4.5s1.2,3.9,7.5,5l7.5,1.2c14.41,2.4,18.21,8.9,18.21,17.4,0,10.91-8.7,17.81-24.51,17.81-17,0-25.5-8.1-25.5-19.31h15.5c.1,4.1,3.3,6.9,10.6,6.9,5.51,0,8.31-1.89,8.31-4.7,0-3-1.61-4.5-8.11-5.6l-7.8-1.3c-13.2-2.1-17.3-8.7-17.3-16.7,0-9.9,8.1-17.5,23.6-17.5S1257.48,308.19,1257.48,318.79Z"/>
        </g>
        <g class="smx-oa-scene-2--ofyour">
          <path class="smx-oa-scene-2--textpath" d="M227.28,449.19c0,16.71-10.7,29.31-29.4,29.31s-29.5-12.6-29.5-29.31,10.7-29.3,29.5-29.3S227.28,432.49,227.28,449.19Zm-42.6,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S184.68,440,184.68,449.19Z"/>
          <path class="smx-oa-scene-2--textpath" d="M255.88,414.39v6.8h11.7v13.2h-11.7V477.1h-15.6V434.39h-9v-13.2h9v-6.7c0-10.9,6.5-17.7,18-17.7h10.9v12.1h-7.7C257.68,408.89,255.88,410.79,255.88,414.39Z"/>
          <path class="smx-oa-scene-2--textpath" d="M364.78,421.19l-31,74.31h-15.1l8.6-20.5-21.8-53.81h15.7l14,34.8,14.5-34.8Z"/>
          <path class="smx-oa-scene-2--textpath" d="M425.08,449.19c0,16.71-10.7,29.31-29.4,29.31s-29.51-12.6-29.51-29.31,10.71-29.3,29.51-29.3S425.08,432.49,425.08,449.19Zm-42.6,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S382.48,440,382.48,449.19Z"/>
          <path class="smx-oa-scene-2--textpath" d="M483.58,421.19V477.1H468v-6c-3.1,4.4-8.5,7.4-15.3,7.4-12.3,0-20.3-9.21-20.3-25.71v-31.6H448v28.7c0,8.5,3.9,13.31,9.9,13.31S468,458.09,468,450v-28.8Z"/>
          <path class="smx-oa-scene-2--textpath" d="M531.78,419.89v16.4h-6.3c-9.2,0-13.4,4.4-13.4,13.9V477.1h-15.6V421.19h15.6v7.6C515.58,423.29,522.08,419.89,531.78,419.89Z"/>
        </g>
        <g class="smx-oa-scene-2--com">
          <path class="smx-oa-scene-2--textpath" d="M591.68,468.2a10,10,0,1,1-10-10.11C587.68,458.09,591.68,462.5,591.68,468.2Z"/>
          <path class="smx-oa-scene-2--textpath" d="M653.08,443.29h-16.4a10.76,10.76,0,0,0-11.2-9.9c-7.4,0-12.5,5.7-12.5,15.8,0,9.9,5.1,15.7,12.5,15.7,6.3,0,10.6-4.2,11.2-9.8h16.4c-.4,13-11.5,23.41-27.6,23.41-18,0-28.8-11.71-28.8-29.31s10.8-29.3,28.8-29.3C641.58,419.89,652.68,430.19,653.08,443.29Z"/>
          <path class="smx-oa-scene-2--textpath" d="M717.78,449.19c0,16.71-10.7,29.31-29.4,29.31s-29.5-12.6-29.5-29.31,10.7-29.3,29.5-29.3S717.78,432.49,717.78,449.19Zm-42.6,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S675.18,440,675.18,449.19Z"/>
          <path class="smx-oa-scene-2--textpath" d="M811.58,445.49V477.1H796V448.19c0-9.2-3.7-14-9.6-14-5.7,0-9.6,5.2-9.6,13.9v29h-15.6V448.19c0-9.2-3.7-14-9.6-14-5.7,0-9.6,5.2-9.6,13.9v29h-15.6V421.19H742v6.1c2.9-4.4,7.9-7.4,14.5-7.4,7.2,0,12.9,3,16.5,8.8a19.65,19.65,0,0,1,16.9-8.8C803,419.89,811.58,429.69,811.58,445.49Z"/>
        </g>
        <g class="smx-oa-scene-2--strategy">
          <path class="smx-oa-scene-2--textpath" d="M905,438.79h-15.6c-.1-3.6-3.3-6.4-8.6-6.4-5.11,0-7.81,1.9-7.81,4.5s1.2,3.9,7.51,5l7.5,1.2c14.4,2.4,18.2,8.9,18.2,17.41,0,10.9-8.7,17.8-24.5,17.8-17,0-25.51-8.1-25.51-19.31h15.5c.11,4.1,3.31,6.91,10.61,6.91,5.5,0,8.3-1.9,8.3-4.71,0-3-1.6-4.5-8.1-5.6l-7.81-1.3c-13.2-2.1-17.3-8.7-17.3-16.7,0-9.9,8.1-17.5,23.61-17.5S905,428.19,905,438.79Z"/>
          <path class="smx-oa-scene-2--textpath" d="M949.88,434.39h-12.1V477.1H922.17V434.39h-10.5v-13.2h10.5v-17.6h15.61v17.6h12.1Z"/>
          <path class="smx-oa-scene-2--textpath" d="M994.38,419.89v16.4h-6.3c-9.2,0-13.4,4.4-13.4,13.9V477.1h-15.6V421.19h15.6v7.6C978.18,423.29,984.68,419.89,994.38,419.89Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1049.58,445.19V477.1H1034V471c-3.9,4.5-10,7.1-18.2,7.1-11.7,0-19-5.9-19-15.6,0-8.81,5.8-15.51,20.41-18l16.8-2.8c0-5.4-3.4-8.5-9.7-8.5-6.1,0-9,2.6-9.4,6.9H999.07c.3-10.3,8.71-19.9,25.21-19.9C1040.38,420.19,1049.58,429.19,1049.58,445.19Zm-15.6,8.4v-.8l-12.8,2.1c-6.7,1.1-8.4,3.4-8.4,6.3,0,2.51,2.4,4.31,6.9,4.31C1028.28,465.5,1034,460.69,1034,453.59Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1095.38,434.39h-12.1V477.1h-15.61V434.39h-10.5v-13.2h10.5v-17.6h15.61v17.6h12.1Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1156.08,453.79h-41.3c.8,6.71,5.3,11.3,12.7,11.3,5.5,0,9.2-1.8,10.9-5H1155c-3.2,10.51-13.4,18-27.7,18-17.5,0-29-11.51-29-28.91s11.31-29,28.91-29,28.9,11.4,28.9,28.8Zm-41.1-10.7h24.6c-1.1-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,1115,443.09Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1221.08,421.19V480.5c0,12.2-7.5,19.8-19.7,19.8h-26.61V487.1h24.11c4.5,0,6.6-2.2,6.6-6.6V469c-3.7,4.8-9.51,7.8-17.41,7.8-14.5,0-24.8-10.1-24.8-28.61s10.3-28.3,24.8-28.3c7.9,0,13.71,2.9,17.41,7.8v-6.5Zm-15.6,26.6c0-9.1-5.2-14.5-12.91-14.5-7.5,0-13,5.4-13,14.5,0,9.3,5.5,14.9,13,14.9C1200.28,462.69,1205.48,457,1205.48,447.79Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1287.18,421.19l-31,74.31h-15.1l8.6-20.5-21.8-53.81h15.7l14,34.8,14.51-34.8Z"/>
          <path class="smx-oa-scene-2--textpath" d="M1301.57,468.2a10,10,0,1,1-10-10.11C1297.57,458.09,1301.57,462.5,1301.57,468.2Z"/>
        </g>
      </g>
      <g id="smx-oa-scene-3" data-name="scene 3">
        <g class="smx-oa-scene-3--we">
          <path class="smx-oa-scene-3--textpath" d="M211,281.19l-22.8,75.91h-17.3L155,305l-16.2,52.11h-16l-22.3-75.91h18.2l14.1,48.5,15.1-48.5h16.3l14.7,48.7,14.6-48.7Z"/>
          <path class="smx-oa-scene-3--textpath" d="M266,333.79h-41.3c.8,6.7,5.3,11.31,12.7,11.31,5.5,0,9.2-1.81,10.9-5h16.6c-3.2,10.51-13.4,18-27.7,18-17.5,0-29-11.5-29-28.91s11.3-29,28.9-29S266,311.59,266,329Zm-41.1-10.7h24.6c-1.1-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,224.88,323.09Z"/>
        </g>
        <g class="smx-oa-scene-3--use">
          <path class="smx-oa-scene-3--textpath" d="M361.18,301.19V357.1h-15.6v-6c-3.1,4.4-8.5,7.4-15.3,7.4-12.3,0-20.31-9.2-20.31-25.71v-31.6h15.61v28.7c0,8.5,3.9,13.3,9.9,13.3s10.1-5.1,10.1-13.2v-28.8Z"/>
          <path class="smx-oa-scene-3--textpath" d="M418.28,318.79h-15.6c-.1-3.6-3.3-6.4-8.6-6.4-5.1,0-7.8,1.9-7.8,4.5s1.2,3.9,7.5,5l7.5,1.2c14.4,2.4,18.2,8.9,18.2,17.4,0,10.91-8.7,17.81-24.5,17.81-17,0-25.51-8.1-25.51-19.31H385c.1,4.1,3.3,6.91,10.6,6.91,5.5,0,8.3-1.9,8.3-4.71,0-3-1.6-4.5-8.1-5.6l-7.8-1.3c-13.2-2.1-17.31-8.7-17.31-16.7,0-9.9,8.11-17.5,23.61-17.5S418.28,308.19,418.28,318.79Z"/>
          <path class="smx-oa-scene-3--textpath" d="M483.88,333.79h-41.3c.8,6.7,5.3,11.31,12.7,11.31,5.5,0,9.2-1.81,10.9-5h16.6c-3.2,10.51-13.4,18-27.7,18-17.5,0-29-11.5-29-28.91s11.31-29,28.91-29,28.9,11.4,28.9,28.8Zm-41.1-10.7h24.6c-1.1-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,442.78,323.09Z"/>
        </g>
        <g class="smx-oa-scene-3--digital">
          <path class="smx-oa-scene-3--textpath" d="M582.18,277.59V357.1h-15.6v-6.6c-3.7,5-9.5,8-17.3,8-14.51,0-24.71-10.4-24.71-29.31s10.2-29.3,24.71-29.3c7.8,0,13.6,3,17.3,7.9v-30.2Zm-15.6,51.2c0-9.9-5.2-15.5-12.8-15.5s-12.9,5.7-12.9,15.5,5.4,15.5,12.9,15.5S566.58,338.69,566.58,328.79Z"/>
          <path class="smx-oa-scene-3--textpath" d="M611.58,285.39a9.09,9.09,0,0,1-9.41,9.4c-5.8,0-9.3-4.3-9.3-9.4s3.5-9.4,9.3-9.4A9,9,0,0,1,611.58,285.39Zm-1.51,14.5V357.1h-15.6V299.89a12.7,12.7,0,0,0,7.8,2.3A12.49,12.49,0,0,0,610.07,299.89Z"/>
          <path class="smx-oa-scene-3--textpath" d="M676.88,301.19V360.5c0,12.2-7.5,19.8-19.7,19.8H630.57V367.1h24.11c4.5,0,6.6-2.2,6.6-6.6V349c-3.7,4.8-9.5,7.8-17.4,7.8-14.51,0-24.81-10.11-24.81-28.61s10.3-28.3,24.81-28.3c7.9,0,13.7,2.9,17.4,7.8v-6.5Zm-15.6,26.6c0-9.1-5.2-14.5-12.9-14.5-7.5,0-13,5.4-13,14.5,0,9.3,5.5,14.9,13,14.9C656.08,342.69,661.28,337,661.28,327.79Z"/>
          <path class="smx-oa-scene-3--textpath" d="M704.08,285.39a9.09,9.09,0,0,1-9.41,9.4c-5.79,0-9.29-4.3-9.29-9.4s3.5-9.4,9.29-9.4A9,9,0,0,1,704.08,285.39Zm-1.5,14.5V357.1H687V299.89a12.73,12.73,0,0,0,7.81,2.3A12.49,12.49,0,0,0,702.58,299.89Z"/>
          <path class="smx-oa-scene-3--textpath" d="M750.28,314.39h-12.1V357.1H722.57V314.39h-10.5v-13.2h10.5v-17.6h15.61v17.6h12.1Z"/>
          <path class="smx-oa-scene-3--textpath" d="M807,325.19V357.1h-15.6V351c-3.9,4.5-10,7.1-18.2,7.1-11.7,0-19-5.9-19-15.61,0-8.8,5.8-15.5,20.41-18l16.8-2.8c0-5.4-3.4-8.5-9.7-8.5-6.1,0-9,2.6-9.4,6.9H756.47c.3-10.3,8.71-19.9,25.21-19.9C797.78,300.19,807,309.19,807,325.19Zm-15.6,8.4v-.8l-12.8,2.1c-6.7,1.1-8.4,3.4-8.4,6.3,0,2.5,2.4,4.31,6.9,4.31C785.68,345.5,791.38,340.69,791.38,333.59Z"/>
          <path class="smx-oa-scene-3--textpath" d="M834.58,277.59V357.1H819V277.59Z"/>
        </g>
        <g class="smx-oa-scene-3--channels">
          <path class="smx-oa-scene-3--textpath" d="M935.58,323.29h-16.4a10.76,10.76,0,0,0-11.2-9.9c-7.4,0-12.5,5.7-12.5,15.8,0,9.9,5.1,15.71,12.5,15.71,6.3,0,10.6-4.21,11.2-9.81h16.4c-.4,13-11.5,23.41-27.6,23.41-18,0-28.81-11.71-28.81-29.31s10.8-29.3,28.81-29.3C924.08,299.89,935.18,310.19,935.58,323.29Z"/>
          <path class="smx-oa-scene-3--textpath" d="M996.08,325.49V357.1h-15.6V328.49c0-8.6-4.1-13.4-10.4-13.4s-10.7,5.2-10.7,13.3l-.11-.7V357.1h-15.6V277.59h15.6v29.9c3.11-4.5,8.71-7.6,15.91-7.6C987.88,299.89,996.08,309,996.08,325.49Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1057.58,325.19V357.1H1042V351c-3.9,4.5-10,7.1-18.21,7.1-11.7,0-19-5.9-19-15.61,0-8.8,5.8-15.5,20.4-18l16.81-2.8c0-5.4-3.4-8.5-9.7-8.5-6.11,0-9,2.6-9.41,6.9h-15.8c.3-10.3,8.7-19.9,25.21-19.9C1048.38,300.19,1057.58,309.19,1057.58,325.19Zm-15.6,8.4v-.8l-12.81,2.1c-6.7,1.1-8.4,3.4-8.4,6.3,0,2.5,2.4,4.31,6.9,4.31C1036.28,345.5,1042,340.69,1042,333.59Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1120.58,325.49V357.1H1105V328.49c0-8.6-4.1-13.4-10.4-13.4s-10.81,5.2-10.81,13.3V357.1h-15.6V301.19h15.6v6.3c3.11-4.5,8.81-7.6,15.91-7.6C1112.38,299.89,1120.58,309,1120.58,325.49Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1184.88,325.49V357.1h-15.6V328.49c0-8.6-4.1-13.4-10.4-13.4s-10.81,5.2-10.81,13.3V357.1h-15.6V301.19h15.6v6.3c3.1-4.5,8.81-7.6,15.91-7.6C1176.68,299.89,1184.88,309,1184.88,325.49Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1250,333.79h-41.31c.8,6.7,5.3,11.31,12.7,11.31,5.5,0,9.2-1.81,10.9-5h16.61c-3.2,10.51-13.41,18-27.71,18-17.5,0-29-11.5-29-28.91s11.3-29,28.9-29S1250,311.59,1250,329Zm-41.11-10.7h24.6c-1.09-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,1208.87,323.09Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1274.37,277.59V357.1h-15.6V277.59Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1332.17,318.79h-15.6c-.1-3.6-3.3-6.4-8.6-6.4-5.1,0-7.8,1.9-7.8,4.5s1.2,3.9,7.5,5l7.5,1.2c14.41,2.4,18.21,8.9,18.21,17.4,0,10.91-8.71,17.81-24.51,17.81-17,0-25.5-8.1-25.5-19.31h15.5c.1,4.1,3.3,6.91,10.6,6.91,5.5,0,8.3-1.9,8.3-4.71,0-3-1.6-4.5-8.1-5.6l-7.8-1.3c-13.2-2.1-17.3-8.7-17.3-16.7,0-9.9,8.1-17.5,23.6-17.5S1332.17,308.19,1332.17,318.79Z"/>
        </g>
        <g class="smx-oa-scene-3--togrow">
          <path class="smx-oa-scene-3--textpath" d="M140.88,434.39h-12.1V477.1h-15.6V434.39h-10.5v-13.2h10.5v-17.6h15.6v17.6h12.1Z"/>
          <path class="smx-oa-scene-3--textpath" d="M202.68,449.19c0,16.7-10.7,29.31-29.4,29.31s-29.5-12.61-29.5-29.31,10.7-29.3,29.5-29.3S202.68,432.49,202.68,449.19Zm-42.6,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S160.08,440,160.08,449.19Z"/>
          <path class="smx-oa-scene-3--textpath" d="M300.68,421.19V480.5c0,12.2-7.5,19.8-19.7,19.8h-26.6V487.1h24.1c4.5,0,6.6-2.2,6.6-6.6V469c-3.7,4.79-9.5,7.8-17.4,7.8-14.5,0-24.8-10.1-24.8-28.61s10.3-28.3,24.8-28.3c7.9,0,13.7,2.9,17.4,7.8v-6.5Zm-15.6,26.6c0-9.1-5.2-14.5-12.9-14.5-7.5,0-13,5.4-13,14.5,0,9.3,5.5,14.91,13,14.91C279.88,462.7,285.08,457,285.08,447.79Z"/>
          <path class="smx-oa-scene-3--textpath" d="M347.28,419.89v16.4H341c-9.2,0-13.4,4.4-13.4,13.9V477.1H312V421.19h15.6v7.6C331.08,423.29,337.58,419.89,347.28,419.89Z"/>
          <path class="smx-oa-scene-3--textpath" d="M409.28,449.19c0,16.7-10.7,29.31-29.4,29.31s-29.5-12.61-29.5-29.31,10.7-29.3,29.5-29.3S409.28,432.49,409.28,449.19Zm-42.6,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S366.68,440,366.68,449.19Z"/>
          <path class="smx-oa-scene-3--textpath" d="M495.88,421.19l-16.4,55.91h-15.7l-11.3-35.51-11.4,35.51h-15.3l-16.4-55.91h15.2l10.5,35.6,11-35.6h13.4l10.9,35.4,10.5-35.4Z"/>
        </g>
        <g class="smx-oa-scene-3--your">
          <path class="smx-oa-scene-3--textpath" d="M590.68,421.19l-31,74.31h-15.1l8.6-20.5-21.8-53.81h15.7l14,34.8,14.5-34.8Z"/>
          <path class="smx-oa-scene-3--textpath" d="M651,449.19c0,16.7-10.7,29.31-29.4,29.31s-29.5-12.61-29.5-29.31,10.7-29.3,29.5-29.3S651,432.49,651,449.19Zm-42.6,0c0,9.2,5.1,15.1,13.2,15.1s13.1-5.9,13.1-15.1-5-15.1-13.1-15.1S608.38,440,608.38,449.19Z"/>
          <path class="smx-oa-scene-3--textpath" d="M709.48,421.19V477.1h-15.6v-6c-3.1,4.4-8.5,7.4-15.3,7.4-12.3,0-20.3-9.21-20.3-25.71v-31.6h15.6v28.7c0,8.5,3.9,13.31,9.9,13.31s10.1-5.11,10.1-13.21v-28.8Z"/>
          <path class="smx-oa-scene-3--textpath" d="M757.68,419.89v16.4h-6.3c-9.2,0-13.4,4.4-13.4,13.9V477.1h-15.6V421.19H738v7.6C741.48,423.29,748,419.89,757.68,419.89Z"/>
        </g>
        <g class="smx-oa-scene-3--business">
          <path class="smx-oa-scene-3--textpath" d="M860.48,449.19c0,18.91-10.3,29.31-24.8,29.31-7.9,0-13.8-3-17.5-8v6.6h-15.6V397.59h15.6v30.2c3.7-4.9,9.6-7.9,17.5-7.9C850.18,419.89,860.48,430.19,860.48,449.19Zm-16.4.3c0-9.7-5.4-15.5-12.9-15.5-7.7,0-13,5.7-13,15.5,0,9.3,5.3,15.61,13,15.61C838.68,465.1,844.08,458.7,844.08,449.49Z"/>
          <path class="smx-oa-scene-3--textpath" d="M919,421.19V477.1h-15.6v-6c-3.1,4.4-8.5,7.4-15.3,7.4-12.3,0-20.3-9.21-20.3-25.71v-31.6h15.6v28.7c0,8.5,3.9,13.31,9.9,13.31s10.1-5.11,10.1-13.21v-28.8Z"/>
          <path class="smx-oa-scene-3--textpath" d="M976.08,438.79h-15.6c-.1-3.6-3.3-6.4-8.6-6.4-5.1,0-7.8,1.9-7.8,4.5s1.2,3.9,7.5,5l7.5,1.2c14.4,2.4,18.2,8.9,18.2,17.4,0,10.91-8.7,17.81-24.5,17.81-17,0-25.5-8.1-25.5-19.31h15.5c.1,4.1,3.3,6.9,10.6,6.9,5.5,0,8.3-1.89,8.3-4.69,0-3-1.6-4.51-8.1-5.61l-7.8-1.3c-13.2-2.1-17.3-8.7-17.3-16.7,0-9.9,8.1-17.5,23.6-17.5S976.08,428.19,976.08,438.79Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1003.68,405.39a9.08,9.08,0,0,1-9.4,9.4c-5.8,0-9.3-4.3-9.3-9.4s3.5-9.4,9.3-9.4A9,9,0,0,1,1003.68,405.39Zm-1.5,14.5V477.1h-15.6V419.89a12.7,12.7,0,0,0,7.8,2.3A12.49,12.49,0,0,0,1002.18,419.89Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1066.88,445.49V477.1h-15.6V448.49c0-8.6-4.1-13.4-10.4-13.4s-10.8,5.2-10.8,13.3V477.1h-15.6V421.19h15.6v6.3c3.1-4.5,8.8-7.6,15.9-7.6C1058.68,419.89,1066.88,429,1066.88,445.49Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1132,453.79h-41.3c.8,6.7,5.3,11.31,12.7,11.31,5.5,0,9.2-1.81,10.9-5h16.6c-3.2,10.51-13.4,18-27.7,18-17.5,0-29-11.5-29-28.91s11.3-29,28.9-29,28.9,11.4,28.9,28.8Zm-41.1-10.7h24.6c-1.1-6.3-6-9.9-12.4-9.9A12.26,12.26,0,0,0,1090.88,443.09Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1187,438.79h-15.6c-.1-3.6-3.3-6.4-8.6-6.4-5.1,0-7.8,1.9-7.8,4.5s1.2,3.9,7.5,5l7.5,1.2c14.4,2.4,18.2,8.9,18.2,17.4,0,10.91-8.7,17.81-24.5,17.81-17,0-25.5-8.1-25.5-19.31h15.5c.1,4.1,3.3,6.9,10.6,6.9,5.5,0,8.3-1.89,8.3-4.69,0-3-1.6-4.51-8.1-5.61l-7.8-1.3c-13.2-2.1-17.3-8.7-17.3-16.7,0-9.9,8.1-17.5,23.6-17.5S1187,428.19,1187,438.79Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1242.88,438.79h-15.6c-.1-3.6-3.3-6.4-8.6-6.4-5.1,0-7.8,1.9-7.8,4.5s1.2,3.9,7.5,5l7.5,1.2c14.4,2.4,18.2,8.9,18.2,17.4,0,10.91-8.7,17.81-24.5,17.81-17,0-25.5-8.1-25.5-19.31h15.5c.1,4.1,3.3,6.9,10.6,6.9,5.5,0,8.3-1.89,8.3-4.69,0-3-1.6-4.51-8.1-5.61l-7.8-1.3c-13.2-2.1-17.3-8.7-17.3-16.7,0-9.9,8.1-17.5,23.6-17.5S1242.88,428.19,1242.88,438.79Z"/>
          <path class="smx-oa-scene-3--textpath" d="M1271.28,468.2a10,10,0,1,1-10-10.11C1267.28,458.09,1271.28,462.49,1271.28,468.2Z"/>
        </g>
      </g>
      <g id="smx-oa-scene-4" data-name="scene 4">
        <path class="smx-oa-scene-4--userpath smx-oa-yellow" d="M1187.2,598.49h-5.37c-3.07,2.81-6.1,5.24-8.67,7.17a10,10,0,0,1-11.92,0c-2.57-1.93-5.6-4.36-8.67-7.17h-5.37a15,15,0,0,0-15,15h0a5,5,0,0,0,5,5h60a5,5,0,0,0,5-5,15,15,0,0,0-15-15Zm-23,3.16a4.92,4.92,0,0,0,5.92,0c7.11-5.31,24.54-19.8,24.54-34.14,0-18-12.32-29-27.5-29s-27.5,11-27.5,29C1139.7,581.85,1157.13,596.34,1164.24,601.65Zm5.46-23.78a9.38,9.38,0,0,1,9.37-9.38h3.75a1.88,1.88,0,0,1,1.88,1.88v1.25a9.37,9.37,0,0,1-9.38,9.37h-3.75a1.87,1.87,0,0,1-1.87-1.87Zm-20-6.25v-1.25a1.88,1.88,0,0,1,1.87-1.88h3.75a9.38,9.38,0,0,1,9.38,9.38v1.25a1.88,1.88,0,0,1-1.88,1.87h-3.75A9.37,9.37,0,0,1,1149.7,571.62Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-red" d="M1073.47,565.77a19.94,19.94,0,0,0,37.17,0,4.92,4.92,0,0,0,3.92-4.78V551a5,5,0,0,0-5-5h-2a19.76,19.76,0,0,0-31,0h-2a5,5,0,0,0-5,5h0v10A4.92,4.92,0,0,0,1073.47,565.77Zm3.59-12.28h30v5h-30Zm29,30h-2.61a27.15,27.15,0,0,1-22.78,0h-2.61a21,21,0,0,0-21,21V611a7.5,7.5,0,0,0,7.5,7.5h55a7.5,7.5,0,0,0,7.5-7.5v-6.5a21,21,0,0,0-21-21Zm6.2,25-.2,0a.62.62,0,0,1-.51-.26,26.21,26.21,0,0,0-3.11-3.91l-.21.06c-.52.15-1.91.85-5.79,3.87a.66.66,0,0,1-.69.06.67.67,0,0,1-.32-.62c.61-5.68,2.48-10.39,5.58-14a.68.68,0,0,1,.49-.22.64.64,0,0,1,.48.25,24,24,0,0,1,4.7,14.11.62.62,0,0,1-.42.61Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-blue" d="M984.73,578.49h4.68v-20h-4.68a2.83,2.83,0,0,0-2.82,2.81h0v14.37a2.81,2.81,0,0,0,2.81,2.81Zm64.37-20h-4.69v20h4.69a2.8,2.8,0,0,0,2.81-2.8h0V561.31a2.8,2.8,0,0,0-2.8-2.82Zm-49.06,30h33.75a5.62,5.62,0,0,0,5.62-5.62V555.52a7,7,0,0,0-7-7h-12.66v-7.18a2.82,2.82,0,1,0-5.63,0v7.18h-12.65a7,7,0,0,0-7,7h0v27.35A5.63,5.63,0,0,0,1000,588.49Zm29.37-7.5h-5v-5h5Zm-2.5-22.5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Zm-12.5,17.5h5v5h-5Zm-5,5h-5v-5h5Zm-2.5-22.5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Zm30,35h-40a15,15,0,0,0-15,15h0v5a5,5,0,0,0,5,5h15v-10a5,5,0,0,1,5-5h20a5,5,0,0,1,5,5h0v10h15a5,5,0,0,0,5-5v-5a15,15,0,0,0-15-15Zm-27.5,15a2.5,2.5,0,0,0-2.5,2.5h0v7.5h5V611a2.49,2.49,0,0,0-2.5-2.5Zm15,0a2.5,2.5,0,1,0,2.5,2.5,2.5,2.5,0,0,0-2.5-2.5Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-yellow" d="M976.75,553.77a2.86,2.86,0,0,0-3.43.82c-.07.09-7,9.62-30.08,9.62s-29.94-9.43-30.09-9.62a2.8,2.8,0,0,0-3.4-.84,2.44,2.44,0,0,0-1.38,3,35.63,35.63,0,0,0,14.87,16.67v0a20,20,0,0,0,40,0v0a35.67,35.67,0,0,0,14.86-16.67A2.44,2.44,0,0,0,976.75,553.77Zm-19.52,44.72h-2.6a27.17,27.17,0,0,1-22.79,0h-2.6a20.93,20.93,0,0,0-19.51,13.41,5,5,0,0,0,4.75,6.59H972a5,5,0,0,0,4.75-6.59A20.93,20.93,0,0,0,957.23,598.49Zm4.52-42.25c-1.73-7.74-4.94-17.75-10.06-17.75a7,7,0,0,0-4.27,1.64,6.62,6.62,0,0,1-8.13,0,7,7,0,0,0-4.27-1.64c-5.14,0-8.36,10.08-10.08,17.83,4.08,1.55,10,2.89,18.3,2.89S957.67,557.83,961.75,556.24Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-red" d="M885.26,583.68l-15.85,15.84-15.84-15.84a20.93,20.93,0,0,0-19.16,20.81V611a7.5,7.5,0,0,0,7.5,7.5h55a7.5,7.5,0,0,0,7.5-7.5v-6.5A20.92,20.92,0,0,0,885.26,583.68Zm-45.85-15.19a14.9,14.9,0,0,0,10.82-4.64,20,20,0,1,0,1.29-14.14,14.92,14.92,0,0,0-12.11-6.22,15,15,0,0,0,6.74,12.5A15,15,0,0,0,839.41,568.49Zm22.5-15h15a5,5,0,0,1,5,5h-25A5,5,0,0,1,861.91,553.49Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-blue" d="M809.32,588.59l-14.91,14.9-14.9-14.9a21,21,0,0,0-20.1,20.9V611a7.5,7.5,0,0,0,7.5,7.5h55a7.5,7.5,0,0,0,7.5-7.5v-1.5a20.94,20.94,0,0,0-20.09-20.9ZM761.54,551l1,.24v9.12a3.73,3.73,0,0,0-1.88,3.17,3.68,3.68,0,0,0,1.74,3.08L760,576.31c-.26,1.07.33,2.18,1.19,2.18h6.53c.86,0,1.45-1.11,1.19-2.18l-2.44-9.74a3.67,3.67,0,0,0,1.73-3.08,3.72,3.72,0,0,0-1.87-3.17V552.1l10.31,2.49a20,20,0,1,0,37.81,8.9,19.58,19.58,0,0,0-2.18-8.9l15-3.63a2.48,2.48,0,0,0,0-4.92l-29.75-7.19a13.53,13.53,0,0,0-6.2,0L761.54,546A2.5,2.5,0,0,0,761.54,551Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-yellow" d="M698.16,566h42.5a1.24,1.24,0,0,0,1.25-1.25v-5a1.24,1.24,0,0,0-1.25-1.25h-1.25a17.48,17.48,0,0,0-10.68-16.12L724.41,551V541a2.49,2.49,0,0,0-2.5-2.5h-5a2.5,2.5,0,0,0-2.5,2.5v10l-4.31-8.62a17.5,17.5,0,0,0-10.69,16.12h-1.25a1.25,1.25,0,0,0-1.25,1.25v5A1.25,1.25,0,0,0,698.16,566Zm35.25,27.5h-2.6a27.17,27.17,0,0,1-22.79,0h-2.61a21,21,0,0,0-21,21h0a4,4,0,0,0,4,4h62a4,4,0,0,0,4-4h0a21,21,0,0,0-21-21Zm-14-5A19.92,19.92,0,0,0,739.16,571H699.67A19.92,19.92,0,0,0,719.41,588.49Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-red" d="M659.32,588.49,644.41,603.4l-14.9-14.91a21,21,0,0,0-20.1,20.91,9.1,9.1,0,0,0,9.1,9.09h51.81a9.09,9.09,0,0,0,9.09-9.09A21,21,0,0,0,659.32,588.49ZM644.41,586a20,20,0,0,0,20-20V548.78a5,5,0,0,0-3.24-4.69l-13.24-5a10,10,0,0,0-7,0l-13.24,5a5,5,0,0,0-3.25,4.68V566A20,20,0,0,0,644.41,586Zm-6.25-36.3a.78.78,0,0,1,.79-.78h3.38v-3.39a.78.78,0,0,1,.78-.78h2.61a.78.78,0,0,1,.78.78v3.39h3.38a.78.78,0,0,1,.78.78v2.6a.78.78,0,0,1-.78.79H646.5v3.38a.78.78,0,0,1-.78.78h-2.61a.78.78,0,0,1-.78-.78v-3.38H639a.79.79,0,0,1-.79-.79Zm-6.25,13.8h25V566a12.5,12.5,0,0,1-25,0Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-blue" d="M569.41,578.49a20,20,0,1,0-20-20A20,20,0,0,0,569.41,578.49Zm15,5.1-7.47,29.9-5-21.25,5-8.75h-15l5,8.75-5,21.25-7.46-29.9a21,21,0,0,0-20,20.9V611a7.5,7.5,0,0,0,7.5,7.5h55a7.5,7.5,0,0,0,7.5-7.5v-6.5a21,21,0,0,0-20-20.9Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-yellow" d="M469.41,573.49h2.11a25,25,0,0,0,45.79,0h2.1a2.5,2.5,0,0,0,2.5-2.5V556a2.51,2.51,0,0,0-2.5-2.5h-2.1a25,25,0,0,0-45.79,0h-2.11a2.51,2.51,0,0,0-2.5,2.5v15A2.51,2.51,0,0,0,469.41,573.49Zm6.25-13.75c0-3.45,3.36-6.25,7.5-6.25h22.5c4.15,0,7.5,2.8,7.5,6.25v3.75a15,15,0,0,1-15,15h-7.5a15,15,0,0,1-15-15ZM486.91,571l1.88-5.62,5.62-1.88-5.62-1.87L486.91,556,485,561.62l-5.63,1.87,5.63,1.88Zm23.69,17.72a29.79,29.79,0,0,1-32.37,0,21,21,0,0,0-18.82,20.78V611a7.5,7.5,0,0,0,7.5,7.5h12.5v-10a5,5,0,0,1,5-5h20a5,5,0,0,1,5,5v10h12.5a7.5,7.5,0,0,0,7.5-7.5v-1.5A20.94,20.94,0,0,0,510.6,588.71Zm-8.69,19.78a2.5,2.5,0,1,0,2.5,2.5A2.51,2.51,0,0,0,501.91,608.49Zm-15,0a2.51,2.51,0,0,0-2.5,2.5v7.5h5V611A2.51,2.51,0,0,0,486.91,608.49Z"/>
        <path class="smx-oa-scene-4--userpath smx-oa-red" d="M419.41,578.49a20,20,0,1,0-20-20A20,20,0,0,0,419.41,578.49Zm14,5h-2.6a27.22,27.22,0,0,1-22.79,0h-2.61a21,21,0,0,0-21,21V611a7.5,7.5,0,0,0,7.5,7.5h55a7.5,7.5,0,0,0,7.5-7.5v-6.5A21,21,0,0,0,433.41,583.49Z"/>
        <path class="smx-oa-scene-4--iconpath" d="M371.08,605.16h-90V541.83a3.33,3.33,0,0,0-3.33-3.34h-6.67a3.33,3.33,0,0,0-3.33,3.34v70a6.66,6.66,0,0,0,6.66,6.66h96.67a3.33,3.33,0,0,0,3.33-3.33v-6.67A3.33,3.33,0,0,0,371.08,605.16Z"/>
        <path class="smx-oa-scene-4--iconpath" d="M364.41,545.16H339.82a5,5,0,0,0-3.54,8.53l6.75,6.75-15.28,15.29-15.29-15.28a6.65,6.65,0,0,0-9.42,0l-14.31,14.31a3.31,3.31,0,0,0,0,4.71l4.71,4.71a3.31,3.31,0,0,0,4.71,0l9.6-9.59L323,589.87a6.66,6.66,0,0,0,9.43,0l20-20,6.75,6.75a5,5,0,0,0,8.54-3.53v-24.6A3.33,3.33,0,0,0,364.41,545.16Z"/>
        <g class="smx-oa-scene-4--we">
          <path class="smx-oa-scene-4--textpath" d="M204.51,291.75,184,360.06H168.42l-14.31-46.89-14.57,46.89h-14.4l-20.07-68.31h16.38l12.68,43.65,13.59-43.65h14.67l13.23,43.83,13.14-43.83Z"/>
          <path class="smx-oa-scene-4--textpath" d="M254,339.09H216.85c.72,6,4.77,10.17,11.43,10.17,4.94,0,8.27-1.62,9.8-4.5H253C250.14,354.21,241,361,228.09,361,212.35,361,202,350.61,202,335s10.17-26.1,26-26.1,26,10.26,26,25.92Zm-37-9.63h22.13c-1-5.67-5.4-8.91-11.16-8.91A11,11,0,0,0,217,329.46Z"/>
        </g>
        <g class="smx-oa-scene-4--boost">
          <path class="smx-oa-scene-4--textpath" d="M346.62,335c0,17-9.27,26.37-22.32,26.37-7.11,0-12.42-2.7-15.75-7.2v5.94h-14V288.51h14v27.18c3.33-4.41,8.64-7.11,15.75-7.11C337.35,308.58,346.62,317.85,346.62,335Zm-14.76.27c0-8.73-4.86-14-11.61-14-6.93,0-11.7,5.13-11.7,14,0,8.37,4.77,14,11.7,14C327,349.26,331.86,343.5,331.86,335.22Z"/>
          <path class="smx-oa-scene-4--textpath" d="M404.76,335c0,15-9.63,26.37-26.46,26.37S351.75,350,351.75,335s9.63-26.37,26.55-26.37S404.76,319.92,404.76,335Zm-38.34,0c0,8.28,4.59,13.59,11.88,13.59s11.79-5.31,11.79-13.59-4.5-13.59-11.79-13.59S366.42,326.67,366.42,335Z"/>
          <path class="smx-oa-scene-4--textpath" d="M462.9,335c0,15-9.63,26.37-26.46,26.37S409.9,350,409.9,335s9.63-26.37,26.54-26.37S462.9,319.92,462.9,335Zm-38.33,0c0,8.28,4.59,13.59,11.87,13.59s11.79-5.31,11.79-13.59-4.5-13.59-11.79-13.59S424.57,326.67,424.57,335Z"/>
          <path class="smx-oa-scene-4--textpath" d="M512.49,325.59h-14c-.09-3.24-3-5.76-7.75-5.76-4.59,0-7,1.71-7,4.05s1.08,3.51,6.75,4.5l6.75,1.08c12.95,2.16,16.37,8,16.37,15.66,0,9.81-7.83,16-22,16-15.3,0-22.95-7.29-22.95-17.37h13.95c.09,3.69,3,6.21,9.54,6.21,4.94,0,7.47-1.71,7.47-4.23,0-2.7-1.45-4.05-7.29-5l-7-1.17c-11.88-1.89-15.57-7.83-15.57-15,0-8.91,7.29-15.75,21.24-15.75S512.49,316.05,512.49,325.59Z"/>
          <path class="smx-oa-scene-4--textpath" d="M552.91,321.63H542v38.43H528V321.63h-9.45V309.75H528V293.91h14v15.84h10.89Z"/>
        </g>
        <g class="smx-oa-scene-4--social">
          <path class="smx-oa-scene-4--textpath" d="M633.9,325.59h-14c-.09-3.24-3-5.76-7.74-5.76-4.58,0-7,1.71-7,4.05s1.07,3.51,6.74,4.5l6.75,1.08c13,2.16,16.38,8,16.38,15.66,0,9.81-7.83,16-22.05,16-15.29,0-22.94-7.29-22.94-17.37h13.94c.1,3.69,3,6.21,9.54,6.21,4.95,0,7.47-1.71,7.47-4.23,0-2.7-1.44-4.05-7.29-5l-7-1.17c-11.87-1.89-15.56-7.83-15.56-15,0-8.91,7.29-15.75,21.23-15.75S633.9,316.05,633.9,325.59Z"/>
          <path class="smx-oa-scene-4--textpath" d="M693.93,335c0,15-9.63,26.37-26.46,26.37S640.93,350,640.93,335s9.63-26.37,26.54-26.37S693.93,319.92,693.93,335Zm-38.33,0c0,8.28,4.59,13.59,11.87,13.59s11.79-5.31,11.79-13.59-4.5-13.59-11.79-13.59S655.6,326.67,655.6,335Z"/>
          <path class="smx-oa-scene-4--textpath" d="M749.82,329.64H735.07A9.69,9.69,0,0,0,725,320.73c-6.66,0-11.25,5.13-11.25,14.22,0,8.91,4.59,14.13,11.25,14.13a9.68,9.68,0,0,0,10.08-8.82h14.75c-.36,11.7-10.35,21.06-24.83,21.06-16.2,0-25.92-10.53-25.92-26.37s9.72-26.37,25.92-26.37C739.47,308.58,749.46,317.85,749.82,329.64Z"/>
          <path class="smx-oa-scene-4--textpath" d="M771.61,295.53a8.42,8.42,0,1,1-16.83,0c0-4.68,3.15-8.46,8.37-8.46A8.13,8.13,0,0,1,771.61,295.53Zm-1.35,13.05v51.48h-14V308.58a11.43,11.43,0,0,0,7,2.07A11.24,11.24,0,0,0,770.26,308.58Z"/>
          <path class="smx-oa-scene-4--textpath" d="M826.42,331.35v28.71h-14v-5.49c-3.51,4.05-9,6.39-16.38,6.39-10.53,0-17.1-5.31-17.1-14,0-7.92,5.22-13.95,18.36-16.2l15.12-2.52c0-4.86-3.06-7.65-8.73-7.65-5.49,0-8.1,2.34-8.46,6.21H781c.27-9.27,7.83-17.91,22.68-17.91C818.14,308.85,826.42,317,826.42,331.35Zm-14,7.56v-.72l-11.52,1.89c-6,1-7.56,3.06-7.56,5.67,0,2.25,2.16,3.87,6.21,3.87C807.25,349.62,812.38,345.3,812.38,338.91Z"/>
          <path class="smx-oa-scene-4--textpath" d="M851.26,288.51v71.55h-14V288.51Z"/>
        </g>
        <g class="smx-oa-scene-4--engagement">
          <path class="smx-oa-scene-4--textpath" d="M943.41,339.09H906.25c.71,6,4.76,10.17,11.42,10.17,5,0,8.28-1.62,9.81-4.5h14.94c-2.88,9.45-12.06,16.2-24.93,16.2-15.74,0-26.09-10.35-26.09-26s10.17-26.1,26-26.1,26,10.26,26,25.92Zm-37-9.63h22.14c-1-5.67-5.4-8.91-11.16-8.91A11,11,0,0,0,906.42,329.46Z"/>
          <path class="smx-oa-scene-4--textpath" d="M998.13,331.62v28.44h-14V334.32c0-7.74-3.69-12.06-9.36-12.06s-9.72,4.68-9.72,12v25.83H951V309.75h14v5.67c2.79-4.05,7.92-6.84,14.31-6.84C990.75,308.58,998.13,316.77,998.13,331.62Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1057.26,309.75v53.37c0,11-6.75,17.82-17.73,17.82h-23.94V369.06h21.69c4,0,5.94-2,5.94-5.94V352.77c-3.33,4.32-8.55,7-15.66,7-13,0-22.31-9.09-22.31-25.74s9.26-25.47,22.31-25.47c7.11,0,12.33,2.61,15.66,7v-5.85Zm-14,23.94c0-8.19-4.68-13.05-11.61-13.05-6.75,0-11.69,4.86-11.69,13.05,0,8.37,4.94,13.41,11.69,13.41C1038.54,347.1,1043.22,342,1043.22,333.69Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1113.15,331.35v28.71h-14v-5.49c-3.51,4.05-9,6.39-16.38,6.39-10.53,0-17.1-5.31-17.1-14,0-7.92,5.22-13.95,18.36-16.2l15.12-2.52c0-4.86-3.06-7.65-8.73-7.65-5.49,0-8.1,2.34-8.46,6.21H1067.7c.27-9.27,7.83-17.91,22.68-17.91C1104.87,308.85,1113.15,317,1113.15,331.35Zm-14,7.56v-.72l-11.52,1.89c-6,1-7.56,3.06-7.56,5.67,0,2.25,2.16,3.87,6.21,3.87C1094,349.62,1099.11,345.3,1099.11,338.91Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1171.83,309.75v53.37c0,11-6.75,17.82-17.73,17.82h-23.93V369.06h21.68c4.05,0,5.94-2,5.94-5.94V352.77c-3.33,4.32-8.55,7-15.66,7-13.05,0-22.31-9.09-22.31-25.74s9.26-25.47,22.31-25.47c7.11,0,12.33,2.61,15.66,7v-5.85Zm-14,23.94c0-8.19-4.68-13.05-11.61-13.05-6.75,0-11.69,4.86-11.69,13.05,0,8.37,4.94,13.41,11.69,13.41C1153.11,347.1,1157.79,342,1157.79,333.69Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1230.51,339.09h-37.16c.72,6,4.77,10.17,11.43,10.17,4.94,0,8.27-1.62,9.8-4.5h14.94c-2.88,9.45-12.06,16.2-24.93,16.2-15.74,0-26.09-10.35-26.09-26s10.17-26.1,26-26.1,26,10.26,26,25.92Zm-37-9.63h22.14c-1-5.67-5.41-8.91-11.17-8.91A11,11,0,0,0,1193.53,329.46Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1271.28,326.67V338.1h-34.55V326.67Z"/>
          <path class="smx-oa-scene-4--textpath" d="M187.32,439.62v28.44h-14v-26c0-8.28-3.33-12.6-8.64-12.6-5.13,0-8.64,4.68-8.64,12.51v26.1H142v-26c0-8.28-3.33-12.6-8.63-12.6-5.13,0-8.64,4.68-8.64,12.51v26.1h-14V417.75h14v5.49a15.08,15.08,0,0,1,13.05-6.66c6.47,0,11.6,2.7,14.84,7.92a17.69,17.69,0,0,1,15.21-7.92C179.58,416.58,187.32,425.4,187.32,439.62Z"/>
          <path class="smx-oa-scene-4--textpath" d="M246.27,447.09H209.11c.72,6,4.77,10.17,11.43,10.17,5,0,8.27-1.62,9.8-4.5h14.94c-2.88,9.45-12.06,16.2-24.92,16.2-15.75,0-26.1-10.35-26.1-26s10.17-26.1,26-26.1,26,10.26,26,25.92Zm-37-9.63h22.13c-1-5.67-5.39-8.91-11.15-8.91A11,11,0,0,0,209.29,437.46Z"/>
          <path class="smx-oa-scene-4--textpath" d="M301,439.62v28.44H287V442.32c0-7.74-3.69-12.06-9.36-12.06s-9.72,4.68-9.72,12v25.83h-14V417.75h14v5.67c2.79-4.05,7.92-6.84,14.31-6.84C293.61,416.58,301,424.77,301,439.62Z"/>
          <path class="smx-oa-scene-4--textpath" d="M342.48,429.63H331.59v38.43h-14V429.63H308.1V417.75h9.45V401.91h14v15.84h10.89Z"/>
        </g>
        <g class="smx-oa-scene-4--and">
          <path class="smx-oa-scene-4--textpath" d="M427.89,439.35v28.71h-14v-5.49c-3.51,4.05-9,6.39-16.38,6.39-10.53,0-17.1-5.31-17.1-14,0-7.92,5.22-13.95,18.36-16.2l15.12-2.52c0-4.86-3.06-7.65-8.73-7.65-5.49,0-8.1,2.34-8.46,6.21H382.44c.27-9.27,7.83-17.91,22.68-17.91C419.61,416.85,427.89,425,427.89,439.35Zm-14,7.56v-.72l-11.52,1.89c-6,1-7.56,3.06-7.56,5.67,0,2.25,2.16,3.87,6.21,3.87C408.72,457.62,413.85,453.3,413.85,446.91Z"/>
          <path class="smx-oa-scene-4--textpath" d="M484.59,439.62v28.44h-14V442.32c0-7.74-3.69-12.06-9.36-12.06s-9.72,4.68-9.72,12v25.83h-14V417.75h14v5.67c2.79-4.05,7.92-6.84,14.31-6.84C477.21,416.58,484.59,424.77,484.59,439.62Z"/>
          <path class="smx-oa-scene-4--textpath" d="M543,396.51v71.55H529v-5.94c-3.33,4.5-8.55,7.2-15.57,7.2-13.05,0-22.23-9.36-22.23-26.37s9.18-26.37,22.23-26.37c7,0,12.24,2.7,15.57,7.11V396.51Zm-14,46.08c0-8.91-4.68-13.95-11.52-13.95s-11.61,5.13-11.61,13.95,4.86,14,11.61,14S529,451.5,529,442.59Z"/>
        </g>
        <g class="smx-oa-scene-4--build">
          <path class="smx-oa-scene-4--textpath" d="M639,443c0,17-9.27,26.37-22.32,26.37-7.11,0-12.42-2.7-15.75-7.2v5.94h-14V396.51h14v27.18c3.33-4.41,8.64-7.11,15.75-7.11C629.76,416.58,639,425.85,639,443Zm-14.76.27c0-8.73-4.86-14-11.61-14-6.93,0-11.7,5.13-11.7,14,0,8.37,4.77,14,11.7,14C619.41,457.26,624.27,451.5,624.27,443.22Z"/>
          <path class="smx-oa-scene-4--textpath" d="M691.68,417.75v50.31h-14v-5.4c-2.79,4-7.65,6.66-13.77,6.66-11.07,0-18.27-8.28-18.27-23.13V417.75h14v25.83c0,7.65,3.51,12,8.91,12s9.09-4.59,9.09-11.88V417.75Z"/>
          <path class="smx-oa-scene-4--textpath" d="M717.6,403.53a8.42,8.42,0,1,1-16.83,0c0-4.68,3.15-8.46,8.37-8.46A8.13,8.13,0,0,1,717.6,403.53Zm-1.35,13.05v51.48h-14V416.58a11.43,11.43,0,0,0,7,2.07A11.24,11.24,0,0,0,716.25,416.58Z"/>
          <path class="smx-oa-scene-4--textpath" d="M741.27,396.51v71.55h-14V396.51Z"/>
          <path class="smx-oa-scene-4--textpath" d="M800.31,396.51v71.55h-14v-5.94c-3.33,4.5-8.55,7.2-15.57,7.2-13.05,0-22.23-9.36-22.23-26.37s9.18-26.37,22.23-26.37c7,0,12.24,2.7,15.57,7.11V396.51Zm-14,46.08c0-8.91-4.68-13.95-11.52-13.95s-11.61,5.13-11.61,13.95,4.86,14,11.61,14S786.27,451.5,786.27,442.59Z"/>
        </g>
        <g class="smx-oa-scene-4--audiences">
          <path class="smx-oa-scene-4--textpath" d="M889.32,439.35v28.71h-14v-5.49c-3.51,4.05-9,6.39-16.38,6.39-10.53,0-17.1-5.31-17.1-14,0-7.92,5.22-13.95,18.36-16.2l15.12-2.52c0-4.86-3.06-7.65-8.73-7.65-5.49,0-8.1,2.34-8.46,6.21H843.87c.27-9.27,7.83-17.91,22.68-17.91C881,416.85,889.32,425,889.32,439.35Zm-14,7.56v-.72l-11.52,1.89c-6,1-7.56,3.06-7.56,5.67,0,2.25,2.16,3.87,6.21,3.87C870.15,457.62,875.28,453.3,875.28,446.91Z"/>
          <path class="smx-oa-scene-4--textpath" d="M945,417.75v50.31H931v-5.4c-2.79,4-7.65,6.66-13.77,6.66-11.07,0-18.27-8.28-18.27-23.13V417.75h14v25.83c0,7.65,3.51,12,8.91,12S931,451,931,443.67V417.75Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1004.61,396.51v71.55h-14v-5.94c-3.33,4.5-8.55,7.2-15.57,7.2-13,0-22.23-9.36-22.23-26.37S962,416.58,975,416.58c7,0,12.24,2.7,15.57,7.11V396.51Zm-14,46.08c0-8.91-4.68-13.95-11.52-13.95s-11.61,5.13-11.61,13.95,4.86,14,11.61,14S990.57,451.5,990.57,442.59Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1031.07,403.53a8.42,8.42,0,1,1-16.83,0c0-4.68,3.15-8.46,8.37-8.46A8.13,8.13,0,0,1,1031.07,403.53Zm-1.35,13.05v51.48h-14V416.58a11.43,11.43,0,0,0,7,2.07A11.24,11.24,0,0,0,1029.72,416.58Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1088.58,447.09h-37.17c.72,6,4.77,10.17,11.43,10.17,5,0,8.28-1.62,9.81-4.5h14.94c-2.88,9.45-12.06,16.2-24.93,16.2-15.75,0-26.1-10.35-26.1-26s10.17-26.1,26-26.1,26,10.26,26,25.92Zm-37-9.63h22.14c-1-5.67-5.4-8.91-11.16-8.91A11,11,0,0,0,1051.59,437.46Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1143.3,439.62v28.44h-14V442.32c0-7.74-3.69-12.06-9.36-12.06s-9.72,4.68-9.72,12v25.83h-14V417.75h14v5.67c2.79-4.05,7.92-6.84,14.31-6.84C1135.92,416.58,1143.3,424.77,1143.3,439.62Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1200.63,437.64h-14.76a9.69,9.69,0,0,0-10.08-8.91c-6.66,0-11.25,5.13-11.25,14.22,0,8.91,4.59,14.13,11.25,14.13a9.68,9.68,0,0,0,10.08-8.82h14.76c-.36,11.7-10.35,21.06-24.84,21.06-16.2,0-25.92-10.53-25.92-26.37s9.72-26.37,25.92-26.37C1190.28,416.58,1200.27,425.85,1200.63,437.64Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1257.87,447.09H1220.7c.72,6,4.77,10.17,11.43,10.17,4.95,0,8.28-1.62,9.81-4.5h14.94C1254,462.21,1244.82,469,1232,469c-15.75,0-26.1-10.35-26.1-26s10.17-26.1,26-26.1,26,10.26,26,25.92Zm-37-9.63H1243c-1-5.67-5.4-8.91-11.16-8.91A11,11,0,0,0,1220.88,437.46Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1307.37,433.59h-14c-.09-3.24-3-5.76-7.74-5.76-4.59,0-7,1.71-7,4.05s1.08,3.51,6.75,4.5l6.75,1.08c13,2.16,16.38,8,16.38,15.66,0,9.81-7.83,16-22,16-15.3,0-23-7.29-23-17.37h14c.09,3.69,3,6.21,9.54,6.21,5,0,7.47-1.71,7.47-4.23,0-2.7-1.44-4.05-7.29-5l-7-1.17c-11.88-1.89-15.57-7.83-15.57-15,0-8.91,7.29-15.75,21.24-15.75S1307.37,424.05,1307.37,433.59Z"/>
          <path class="smx-oa-scene-4--textpath" d="M1332.93,460.05a9,9,0,1,1-9-9.09A8.72,8.72,0,0,1,1332.93,460.05Z"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>

import {gsap} from "gsap";

import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin'

gsap.registerPlugin(MorphSVGPlugin, MotionPathPlugin, DrawSVGPlugin);

export default {
  name: "GSAPExampleNr1",


  mounted() {




    const smxOaMasterTl = gsap.timeline({repeat:-1});

    MorphSVGPlugin.convertToPath('#smx-oa-icons--circle__blue');
    MorphSVGPlugin.convertToPath('#smx-oa-icons--circle__yellow');
    MorphSVGPlugin.convertToPath('#smx-oa-icons--circle__red');
    const smxOaScene4UserPaths = Array.prototype.slice.call(document.querySelectorAll('.smx-oa-scene-4--userpath')).reverse();


    smxOaMasterTl
        .add( smxOaScene1() )
        .add( smxOaScene2(), '-=0.4' )
        .add( smxOaScene3(), '-=3' )
        .add( smxOaScene4(), '-=0.4' )
    ;

    function smxOaScene1() {
      let tl = gsap.timeline();

      tl
          .fromTo('.smx-oa-scene-1--welcome .smx-oa-scene-1--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in')

          .fromTo('.smx-oa-scene-1--to .smx-oa-scene-1--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.3')

          .fromTo('.smx-oa-scene-1--swingmix .smx-oa-scene-1--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.6')

          .fromTo('.smx-oa-scene-1--a .smx-oa-scene-1--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.9')

          .fromTo('.smx-oa-scene-1--digital .smx-oa-scene-1--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.2')

          .fromTo('.smx-oa-scene-1--agency .smx-oa-scene-1--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.5')

          .fromTo('.smx-oa-scene-1--circle__blue', {
            scale: 10,
            transformOrigin: '50% 50%'
          }, {
            autoAlpha: 1,
            scale: 1,
            motionPath: {
              path: '#smx-oa-scene-1--motionpath__blue',
              align: '#smx-oa-scene-1--motionpath__blue',
              alignOrigin: [0.5, 0.5],
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.6')

          .fromTo('.smx-oa-scene-1--circle__yellow', {
            scale: 10,
          }, {
            autoAlpha: 1,
            scale: 1,
            motionPath: {
              path: '#smx-oa-scene-1--motionpath__yellow',
              align: '#smx-oa-scene-1--motionpath__yellow',
              alignOrigin: [0.5, 0.5],
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.9')

          .fromTo('.smx-oa-scene-1--circle__red', {
            scale: 10,
            transformOrigin: '50% 50%'
          }, {
            autoAlpha: 1,
            scale: 1,
            motionPath: {
              path: '#smx-oa-scene-1--motionpath__red',
              align: '#smx-oa-scene-1--motionpath__red',
              alignOrigin: [0.5, 0.5],
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.2')

          .to('.smx-oa-scene-1--welcome .smx-oa-scene-1--textpath', {
            fill: '#ffde33',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.2')
          .to('.smx-oa-scene-1--swingmix .smx-oa-scene-1--textpath', {
            fill: '#37a3fa',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.5')
          .to('.smx-oa-scene-1--digital .smx-oa-scene-1--textpath', {
            fill: '#ff736e',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.8')
          .to('.smx-oa-scene-1--agency .smx-oa-scene-1--textpath', {
            fill: '#ff736e',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.8')

          .to('.smx-oa-scene-1--circle__blue', {
            autoAlpha: 0,
            scale: 0,
            motionPath: {
              path: '#smx-oa-scene-1--motionpath__blue',
              align: '#smx-oa-scene-1--motionpath__blue',
              alignOrigin: [0.5, 0.5],
              start: 1,
              end: 0,
            },
            duration:  1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=2.7')

          .to('.smx-oa-scene-1--circle__yellow', {
            autoAlpha: 0,
            scale: 0,
            motionPath: {
              path: '#smx-oa-scene-1--motionpath__yellow',
              align: '#smx-oa-scene-1--motionpath__yellow',
              alignOrigin: [0.5, 0.5],
              start: 1,
              end: 0,
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=2.7')

          .to('.smx-oa-scene-1--circle__red', {
            autoAlpha: 0,
            scale: 0,
            motionPath: {
              path: '#smx-oa-scene-1--motionpath__red',
              align: '#smx-oa-scene-1--motionpath__red',
              alignOrigin: [0.5, 0.5],
              start: 1,
              end: 0,
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=2.7')

          .to('.smx-oa-scene-1--textpath', {
            yPercent: 500,
            autoAlpha: 0,
            duration: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'in+=2.7')

      ;

      return tl;
    }

    function smxOaScene2() {
      let tl = gsap.timeline();

      tl
          .fromTo('.smx-oa-scene-2--we .smx-oa-scene-2--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in')

          .fromTo('.smx-oa-scene-2--connect .smx-oa-scene-2--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.3')

          .fromTo('.smx-oa-scene-2--thedots .smx-oa-scene-2--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.6')

          .fromTo('.smx-oa-scene-2--ofyour .smx-oa-scene-2--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.9')

          .fromTo('.smx-oa-scene-2--com .smx-oa-scene-2--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.2')

          .fromTo('.smx-oa-scene-2--strategy .smx-oa-scene-2--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.5')

          .fromTo('#smx-oa-icons--circle__blue', {
            scale: 0,
            transformOrigin: '50% 50%'
          }, {
            autoAlpha: 1,
            scale: 1,
            motionPath: {
              path: '#smx-oa-icons--path__blue',
              align: '#smx-oa-icons--path__blue',
              alignOrigin: [0.5, 0.5],
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.3')

          .fromTo('#smx-oa-icons--circle__yellow', {
            scale: 0,
          }, {
            autoAlpha: 1,
            scale: 1,
            motionPath: {
              path: '#smx-oa-icons--path__yellow',
              align: '#smx-oa-icons--path__yellow',
              alignOrigin: [0.5, 0.5],
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.6')

          .fromTo('#smx-oa-icons--circle__red', {
            scale: 0,
            transformOrigin: '50% 50%'
          }, {
            autoAlpha: 1,
            scale: 1,
            motionPath: {
              path: '#smx-oa-icons--path__red',
              align: '#smx-oa-icons--path__red',
              alignOrigin: [0.5, 0.5],
            },
            duration: 1,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.9')


          .to('.smx-oa-scene-2--connect .smx-oa-scene-2--textpath', {
            fill: '#37a3fa',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.4')
          .to('.smx-oa-scene-2--com .smx-oa-scene-2--textpath', {
            fill: '#ffde33',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.7')
          .to('.smx-oa-scene-2--strategy .smx-oa-scene-2--textpath', {
            fill: '#ff736e',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=2')

          .to('.smx-oa-scene-2--textpath', {
            xPercent: 500,
            autoAlpha: 0,
            duration: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'exit')

          .add( dots(), 'exit' )

      ;
      return tl;
    }

    function dots() {
      let tl = gsap.timeline({yoyo:true, repeat:3});
      tl
          .to('.smx-oa-icons--circle', {
            yPercent: 100,
            duration: 0.75,
            stagger: 0.08,
            ease: "back.out(3)",
            yoyoEase: true,
            force3D: true,
          }, 'exit')
      return tl;
    }

    function smxOaScene3() {
      let tl = gsap.timeline();

      tl
          .fromTo('.smx-oa-scene-3--we .smx-oa-scene-3--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in')

          .fromTo('.smx-oa-scene-3--use .smx-oa-scene-3--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.3')

          .fromTo('.smx-oa-scene-3--digital .smx-oa-scene-3--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.6')

          .fromTo('.smx-oa-scene-3--channels .smx-oa-scene-3--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.9')

          .fromTo('.smx-oa-scene-3--togrow .smx-oa-scene-3--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.2')

          .fromTo('.smx-oa-scene-3--your .smx-oa-scene-3--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.5')

          .fromTo('.smx-oa-scene-3--business .smx-oa-scene-3--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.8')

          .to('#smx-oa-icons--circle__blue', {
            morphSVG: '#smx-oa-icons--icon__blue',
            duration: 0.5,
            ease: "circ.out",
          }, 'morph')

          .to('#smx-oa-icons--circle__yellow', {
            morphSVG: '#smx-oa-icons--icon__yellow',
            duration: 0.5,
            ease: "circ.out",
          }, 'morph+=0.5')

          .to('#smx-oa-icons--circle__red', {
            morphSVG: '#smx-oa-icons--icon__red',
            duration: 0.5,
            ease: "circ.out",
          }, 'morph+=1')

          .to('.smx-oa-scene-3--digital .smx-oa-scene-3--textpath', {
            fill: '#ff736e',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.7')
          .to('.smx-oa-scene-3--channels .smx-oa-scene-3--textpath', {
            fill: '#ff736e',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.7')
          .to('.smx-oa-scene-3--togrow .smx-oa-scene-3--textpath', {
            fill: '#ffde33',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=2')
          .to('.smx-oa-scene-3--business .smx-oa-scene-3--textpath', {
            fill: '#37a3fa',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=2.3')

          .to('.smx-oa-scene-3--textpath', {
            yPercent: 500,
            autoAlpha: 0,
            duration: 0.5,
            delay: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'exit')

          .to('#smx-oa-icons--circle__blue', {
            scale: 0,
            motionPath: {
              path: '#smx-oa-icons--path__blue',
              align: '#smx-oa-icons--path__blue',
              alignOrigin: [0.5, 0.5],
              start: 1,
              end: 0,
            },
            duration: 0.5,
            delay: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'exit')

          .to('#smx-oa-icons--circle__yellow', {
            scale: 0,
            motionPath: {
              path: '#smx-oa-icons--path__yellow',
              align: '#smx-oa-icons--path__yellow',
              alignOrigin: [0.5, 0.5],
              start: 1,
              end: 0,
            },
            duration: 0.5,
            delay: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'exit')

          .to('#smx-oa-icons--circle__red', {
            scale: 0,
            motionPath: {
              path: '#smx-oa-icons--path__red',
              align: '#smx-oa-icons--path__red',
              alignOrigin: [0.5, 0.5],
              start: 1,
              end: 0,
            },
            duration: 0.5,
            delay: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'exit')

      ;

      return tl;
    }

    function smxOaScene4() {
      let tl = gsap.timeline();

      tl
          .fromTo('.smx-oa-scene-4--we .smx-oa-scene-4--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in')

          .fromTo('.smx-oa-scene-4--boost .smx-oa-scene-4--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.3')

          .fromTo('.smx-oa-scene-4--social .smx-oa-scene-4--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.6')

          .fromTo('.smx-oa-scene-4--engagement .smx-oa-scene-4--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.3,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=0.9')

          .fromTo('.smx-oa-scene-4--and .smx-oa-scene-4--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.2')

          .fromTo('.smx-oa-scene-4--build .smx-oa-scene-4--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.5')

          .fromTo('.smx-oa-scene-4--audiences .smx-oa-scene-4--textpath', {
            yPercent: -100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: "circ.out",
            force3D: true,
          }, 'in+=1.8')

          .to('.smx-oa-scene-4--boost .smx-oa-scene-4--textpath', {
            fill: '#37a3fa',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.9')
          .to('.smx-oa-scene-4--social .smx-oa-scene-4--textpath', {
            fill: '#37a3fa',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=1.9')
          .to('.smx-oa-scene-4--engagement .smx-oa-scene-4--textpath', {
            fill: '#ffde33',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=2.2')
          .to('.smx-oa-scene-4--build .smx-oa-scene-4--textpath', {
            fill: '#ff736e',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=2.5')
          .to('.smx-oa-scene-4--audiences .smx-oa-scene-4--textpath', {
            fill: '#ff736e',
            duration: 0.3,
            ease: "circ.out",
          }, 'in+=2.5')



          .fromTo('.smx-oa-scene-4--iconpath', {
            drawSVG: 0
          }, {
            drawSVG: '100%',
            autoAlpha: 1,
            duration: 1,
            stagger: 0.08
          }, 'in+=1.9')

          .to('.smx-oa-scene-4--iconpath', {
            fill: '#212120'
          }, 'in+=2.9')

          .fromTo(smxOaScene4UserPaths, {
            yPercent: 100
          }, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1.5,
            stagger: 0.04,
            ease: "circ.out",
            force3D: true,
          }, 'in+=2.9')


          .to('.smx-oa-scene-4--textpath', {
            yPercent: 500,
            autoAlpha: 0,
            duration: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'out')

          .to('.smx-oa-scene-4--iconpath', {
            yPercent: 500,
            autoAlpha: 0,
            duration: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'out')

          .to(smxOaScene4UserPaths, {
            yPercent: 500,
            autoAlpha: 0,
            duration: 0.5,
            ease: "circ.out",
            force3D: true,
          }, 'out')

      return tl;
    }}
}
</script>

<style scoped lang="scss">

.smx-opening-animation-wrap {
  width: 100vw;
  height: 100vh;
  background: #faf9fc;
  margin: 0;
  padding: 0;
}

svg.smx-opening-animation {
  width: 100%;
  height: 100%;
}

svg.smx-opening-animation path,
svg.smx-opening-animation circle {
  opacity: 0;
  visibility: hidden;
}
.smx-oa-yellow{fill:#ffde33;}
.smx-oa-red{fill:#ff736e;}
.smx-oa-blue{fill:#37a3fa;}
.smx-oa-stroke{fill:none;stroke:#000;stroke-miterlimit:10;}
.smx-oa-scene-1--textpath,
.smx-oa-scene-2--textpath,
.smx-oa-scene-3--textpath,
.smx-oa-scene-4--textpath{fill:#212120}
.smx-oa-scene-4--iconpath{stroke-width:5px;stroke:#212120;fill:transparent;}
</style>